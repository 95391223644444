import React from 'react';
import './Footer.scss';


const Footer = () => (

    
   
    <div className="footer">
        <div className="footer-contact">
            <h3 className="item">Susie Leorna</h3>
            <h3 className="item">909-663-8135</h3>
            <h3 className="item">susiesbakingcompany@gmail.com</h3>
        </div>
       
    </div>
    );

export default Footer;