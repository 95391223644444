import React from 'react';
import './productcard.scss';

import Logo from '../../images/susies_logo.png';

 
const NewCard = (props) => (

    <div className='card-container'>
        <div className='new-card-text'>
            <h1>{props.name}</h1>
        </div>
        <img className='new-card-img' src={props.image}/>
        
    </div>
);

export default NewCard;