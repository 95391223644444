import React from 'react';
import { withRouter } from 'react-router-dom';
import './details.scss';
import 'animate.css';

import BulletPoint from '../../../images/bullet-list.svg';


const Details = () => (
    <div className='details-list-container animated fadeIn fast'>
        <div className='details-title'>
           ORDERING DETAILS
        </div>
        <div className='details-items'>
            <embed src={BulletPoint} />
            Minimum order of $100 per week
        </div>
        <div className='details-items'>
            <embed src={BulletPoint} />
            Allow at least 36 hours from order to delivery once per week
        </div>
        <div className='details-items'>
            <embed src={BulletPoint} />
            Monthly billing
        </div>
        
    </div>
);

export default withRouter(Details);