import React from 'react';
import { withRouter } from 'react-router-dom';
import AwesomeSlider from 'react-awesome-slider';
import AwsSliderStyles from 'react-awesome-slider/src/styles.js';
import './Home.scss';
import 'animate.css';

import all_cookies from "../../../images/products/all_cookies.jpg";
import power_bar_2 from '../../../images/products/power_bar_2.jpg';
import scones from "../../../images/products/scones.jpg";
import brownie from "../../../images/products/brownie.jpg";
import all_cookies_3 from '../../../images/products/all_cookies_3.jpg';
import cinnamon_roll from '../../../images/products/cinnamon_roll.jpg';
import seasonal_muffin from '../../../images/products/seasonal_muffin.jpg';


import sugar from '../../../images/sugar.svg';
import measuringCups from '../../../images/measuingCups.svg';
import mixer from '../../../images/mixer.svg'

const Home = () => (
    <div className="home-container animated fadeIn fast">
        <div className="photo-gallery-container" >
            <AwesomeSlider
            cssModule={AwsSliderStyles}
            animation='smooth-lettering'
            >       
                <div data-src={all_cookies} />
                <div data-src={power_bar_2} />
                <div data-src={brownie} />
                <div data-src={all_cookies_3} />
                <div data-src={seasonal_muffin} />


            </AwesomeSlider>
        </div>
        <div className="home-page-box-container">
            <div className="home-page-single-box">
                <img src={sugar} />
                <div className="box-text">WHOLESALE SUPPLIER OF COOKIES AND MUFFINS</div>
            </div>
            <div className="home-page-single-box">
                <img src={measuringCups} />
                <div className="box-text">LOCAL CAMANO ISLAND BAKER</div>
            </div>
            <div className="home-page-single-box-last">
                <img src={mixer} />
                <div className="box-text">FRESH, HOMEMADE GOODS BAKED IN A LICENSED, COMMERCIAL KITCHEN</div>
            </div>
        </div>
    </div>
);

export default withRouter(Home);