import React from 'react';
import { withRouter } from 'react-router-dom';
import './Products.scss';
import 'animate.css';

import ProductCard from '../../ProductCard/ProductCard'
import powcook from '../../../images/products/power_bar.jpg';
import monscook from '../../../images/products/monster_cookie.jpg';
import molcook from '../../../images/products/molasses_cookie.jpg';
import snick from '../../../images/products/snickerdoodle_cookie.jpg';
import pbcook from '../../../images/products/pb_cookie.jpg';
import muff from '../../../images/products/seasonal_muffin.jpg';



const Products = () => (
    <div className='product-page animated fadeIn fast'>
        <div className='product-container'>
            <ProductCard name='POWER BAR' image={powcook} />
            <ProductCard name='MONSTER COOKIE' image={monscook}/>
            <ProductCard name='MOLASSES COOKIE' image={molcook}/>
            <ProductCard name='SNICKERDOODLE COOKIE' image={snick}/>
            <ProductCard name='PEANUT BUTTER COOKIE' image={pbcook} />
            <ProductCard name='SEASONAL MUFFIN' image={muff} />

        </div>
    </div>
    
);

export default withRouter(Products);