import React from 'react';
import { NavLink, withRouter} from 'react-router-dom';
import Logo from '../..//images/susies_logo_no_graphic-min.png'
import Whisk from '../../images/Whisk.svg';
import RollingPin from '../../images/RollingPin.svg';
import Spatula from '../../images/Spatula.svg';
import IconButton from './IconButton/IconButton'
import './header.scss';



const Header = () => (
    <div className="header">
        <div className='main-logo-container'>
            <NavLink exact to='/'>
            <img src={Logo} className='logo-img'/>
            </NavLink>
        </div>
        
        <div className="navbar-link-container">      
            <NavLink exact to='/products' className="navbar-link" activeClassName="active-style" >
                <IconButton image={Whisk}>Products</IconButton>
            </NavLink>
            <NavLink to='/details' className="navbar-link" activeClassName="active-style">
                <IconButton image={RollingPin}>Details</IconButton>
            </NavLink>
            <NavLink to='/contact' className="navbar-link" activeClassName="active-style">
                <IconButton image={Spatula}>Contact</IconButton>
            </NavLink>
        </div>
    </div>
);

export default withRouter(Header);