import React from 'react';
import { HashRouter } from 'react-router-dom';
import './App.css';

import PageContent from './PageContent';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';


function App() {
  return (
    <HashRouter>
    <div className="App">
      <Header />
      <PageContent />
      <Footer />
    </div>
    </HashRouter>
  );
}

export default App;
