import React from 'react';
import './IconButton.scss';


const IconButton = (props) => (
    <div className='button-container'>
        <div className='img-container'>
            <img className='button-img' src={props.image}/>
            <div className='button-name'>
                {props.children}
            </div>
        </div>
    </div>
);

export default IconButton;