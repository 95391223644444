import React from 'react';
import { withRouter } from 'react-router-dom';
import './contact.scss';
import 'animate.css';

import Logo from '../../../images/susies_logo.png';

const Contact = () => (
    <div className="contact-page-container animated fadeIn fast">
        <div className="contact-information">
            <img src={Logo} className="contact-logo" />
            SUSIE LEORNA <br />
            909-663-8135 <br />
            SUSIESBAKINGCOMPANY@GMAIL.COM
        </div>
        <div className="contact-form">
            <form name="contact-form" method="POST">
            <input type="hidden" name="form-name" value="contact-form" />
                <div className="name-container">
                    <h5>NAME:</h5>
                    <input type="text" name="name" placeholder="Name" className="red-border"></input>
                </div>
                <div className="email-container">
                    <h5>EMAIL:</h5>
                    <input type="email" name="email" placeholder="Email" className="red-border" ></input>
                </div>
                <div className="message-container">
                    <h5>MESSAGE:</h5>
                    <textarea name="message" rows="7" placeholder="Message" className="red-border"></textarea>
                </div>
                <input type="submit" className="submit-button" name="submit" value="SEND" ></input>
            </form>
        </div>
    </div>
);

export default withRouter(Contact);