import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import './PageContent.scss'

import Home from './Components/layout/Home/Home';
import Products from './Components/layout/Products/Products';
import Details from './Components/layout/Details/Details';
import Contact from './Components/layout/Contact/Contact';

const PageContent = () => (
    <div className='overall-page-content'>
        <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/products" component={Products} />
            <Route path="/details" component={Details} />
            <Route path="/contact" component={Contact} />
        </Switch>   
    </div>
)

export default PageContent;